import React, { Component } from 'react'
import { Button, Navbar, Nav, NavDropdown, Form } from 'react-bootstrap';
import $ from 'jquery'

import { logout } from '../services/auth'
import { URL } from '../constants/index'
import Logo from '../assets/LogoBranco.png';

class NavBI extends Component {

    constructor(props){
        super(props)

        this.onDashboardMensal = this.onDashboardMensal.bind(this)
        this.onDashboardAtual = this.onDashboardAtual.bind(this)
        this.onLogout = this.onLogout.bind(this)
    }

    getMenuDashboard = linkMenu => {
      if (!linkMenu) {
        return
      }

      if($(".powerbi-frame") === undefined){
        return
      }

      var report = window.powerbi.get($(".powerbi-frame")[0])
      report.setPage(linkMenu)
        .then(function () {
        })
        .catch(function (errors) {
        });
    }

    onLogout() {
      logout()
        .then(res => this.props.history.push(URL.URLLogin))
        .catch(res => this.props.history.push(URL.URLLogin))
    }

    onDashboardMensal(){
      this.props.history.push(URL.URLDashboardMensal)
    }

    onDashboardAtual(){
      this.props.history.push(URL.URLDashboardAtual)
    }

    render() {
      const menu = this.props.menu;
      return (

      <Navbar style={{ backgroundColor: "#000" }} expand="lg" variant= "dark">
        <img src={Logo} height="40" width="40" />

        <Navbar.Brand href="" style={{ color: "#fff", marginLeft: "10px" }}>Dashboard - Volpe BI</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto" style={{ color: "#fff" }}>
                  <NavDropdown
                    title = "Menu"
                    id = "basic-nav-dropdown"
                    style={{ backgroundColor: "#343a40", border: "1px solid #fff", fontWeight: "700" }}
                    size={38}
                  >
                    {
                      menu && menu.length >= 0 ?
                        menu.map((elemento, i) => {
                          return (<NavDropdown.Item className="item-nav-dropdown" style={{ color: "#000" }} key={i} onClick={() => this.getMenuDashboard(elemento.link)}>{elemento.menu}</NavDropdown.Item>)
                        })
                        :
                        null
                    }
                  </NavDropdown>
                </Nav>
                <Form inline>
                    <Button
                      variant="outline-light"
                      className="mr-sm-2"
                      onClick={this.onLogout}
                      style={{ padding: "8px", margin: "1px" }}
                    >
                      Sair
                    </Button>
                </Form>
            </Navbar.Collapse>
        </Navbar>
    )
  }
}

export default NavBI;
