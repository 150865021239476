import React, { useState, useEffect, useRef, createRef } from 'react'
import Report from 'react-powerbi';
import { Container, Row } from 'reactstrap';
import ReactLoading from "react-loading";
import MediaQuery from 'react-responsive';
import { useHistory } from 'react-router-dom';

import api from '../../services/api'
import { obterNovaAutenticacao, logout, login } from '../../services/auth'
import NavBI from '../../components/NavBI'
import NavBIMobile from '../../components/NavBIMobile'
import { URL } from '../../constants/index'

const Dashboard = (props) => {
  const [idReport, setIdReport] = useState("");
  const [embedUrl, setEmbedUrl] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingErro, setLoadingErro] = useState(false);
  const [menu, setMenu] = useState([]);

  const history = useHistory();

  const myRef = createRef();
  const userRef = useRef();

  var TimerDashboard;
  var TimerAutenticacao;

  const onEmbedded = (embed) => {
  }

  useEffect( ()=> {
    getDashboard();
    startTimerAutenticacao();
    startTimerDashboard();

    const menu = [];

    setMenu(menu);
  },[]);

  // Efeito no final da rotina
  useEffect(() => {
    return () => {
      stopTimerDashboard();
      stopTimerAutenticacao();
    }
  }, []);

  const startTimerDashboard = () => {
    TimerDashboard = setInterval(() => { getDashboard(); }, 7200000);
  }

  const stopTimerDashboard = () => {
    clearInterval(TimerDashboard);
  }

  const startTimerAutenticacao = () => {
    TimerAutenticacao = setInterval(() => { getNovaAutenticacao() }, 120000);
  }

  const stopTimerAutenticacao = () => {
    clearInterval(TimerAutenticacao);
  }

  const getNovaAutenticacao = () => {
    const response = obterNovaAutenticacao();

    response.then(res => {
      login(res.data);
    })
    response.catch(err => {
      onLogout();
    })
  }

  const scrollToMyRef = () => {
    // Validar código posteriormente
    if(!myRef){
      window.scrollTo(0, myRef.current.rootElement.offsetTop)
    }
  }

  const getDashboard = () => {
    setLoading(true);

    const urlAPIDashboard = "/api/dashboard/" + props.URLDashboard;

    api.get(urlAPIDashboard)
      .then(res => {
        setEmbedUrl(res.data.embedURL);
        setIdReport(res.data.reportID);
        setAccessToken(res.data.accessToken);
        setLoading(false);
        setLoadingErro(false);
        setMenu(res.data.listaMenu);
        scrollToMyRef();
      })
      .catch(err => {
        setLoadingErro(true);
        setLoading(false);
      })
  }

  const onLogout = () => {
    logout()
      .then(res => props.history.push(URL.URLLogin))
      .catch(res => props.history.push(URL.URLLogin))
  }

  if (loading) {
    return (
      <div id="content-bi">
        <NavBI menu={menu} history={props.history} />

        <Container>
          <Row className="justify-content-center">
            <ReactLoading
              type="bubbles"
              color="#343a40"
              height={100}
              width={100}
            />
          </Row>
        </Container>
      </div>
    )
  }

  if (loadingErro) {
    return (
      <div id="content-bi">
        <NavBI menu={menu} history={props.history} />

        <Container>
          <Row className="justify-content-center">
            <h3>Ocorreu um problema ao carregar dashboard</h3>
          </Row>
        </Container>
      </div>
    )
  }

  return (
    <div id="content-bi">

      <MediaQuery minDeviceWidth={1224}>
        <NavBI
          menu={menu}
          history={history}
        />
        <Report
          ref={myRef}
          id={idReport}
          embedUrl={embedUrl}
          accessToken={accessToken}
          filterPaneEnabled={false}
          mobile={false}
          navContentPaneEnabled={false}
          onEmbedded={onEmbedded}
          embedType='report'
          pageName={props.pageName}
          permissions="Read"
          tokenType="Aad"
          width="100%"
          height="93.5%"
        />
      </MediaQuery>

      <MediaQuery maxDeviceWidth={1224}>
        <NavBIMobile
          menu={menu}
          history={history}
        />
        <Report
          ref={myRef}
          id={idReport}
          embedUrl={embedUrl}
          accessToken={accessToken}
          filterPaneEnabled={false}
          mobile={true}
          navContentPaneEnabled={false}
          onEmbedded={onEmbedded}
          embedType='report'
          pageName={props.pageNameMobile}
          permissions="Read"
          tokenType="Aad"
          width="100%"
          height="100%"
        />
      </MediaQuery>
    </div>
  )
}

export default Dashboard;
