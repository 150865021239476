import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import ReactLoading from "react-loading";
import {confirmarAutenticacao, obterNovaAutenticacao} from '../services/auth'

export default function withAuth(ComponentToProtect) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
                redirect: false,
            };        

            this.tratarAutenticado.bind(this)
            this.tratarNaoAutenticado.bind(this)
        }

        tratarAutenticado(){            
            this.setState({loading: false, redirect: false})
        }

        tratarNaoAutenticado(){
            this.setState({loading: false, redirect: true})
        }

        componentDidMount() {

            var confirmarAuth = confirmarAutenticacao();
            confirmarAuth.then(res => {                
                this.tratarAutenticado();                
            })

            confirmarAuth.catch(err => {  
                
                const response = obterNovaAutenticacao();
        
                response.then(res => {            
                    this.tratarAutenticado();
                })
                response.catch(err => {                                          
                    this.tratarNaoAutenticado();
                })                                  
            })                                
        }
        
        render() {
            const { loading, redirect } = this.state;
            if (loading) {
                return <Container>
                    <Row className="justify-content-center">
                        <ReactLoading type="bubbles" color="#343a40" height={100} width={100} />
                    </Row>
                </Container>;
            }
            if (redirect) {
                return <Redirect to="/login" />;
            }
            return <ComponentToProtect {...this.props} />;
        }
    }
}