//export const PBIUrlAPI = "http://bi-api.volpelab.com.br"
export const PBIUrlAPI = "http://cloudequipef3-app.pwi.com.br:9101/";
//export const PBIUrlAPI = "http://localhost:3105/"

export const URL = {
  URLLogin: "/login",
  URLCadastrarSenha: "/cadastrarsenha",
  URLRecuperarSenha: "/recuperarsenha",
  URLPrimeiroLogin: "/primeirologin",
  URLDashboardMensal: "/dashboardmensal",
  URLDashboardAtual: "/dashboardatual",
};
