import React from "react";

export const LabelErro = props => (
  <p style={{ color: 'red' }}>
    <strong>
      {props.caption}
    </strong>
  </p>
)


