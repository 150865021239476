import React, { useState } from 'react'
import { Container, Row, Col } from 'reactstrap';
import ReactLoading from "react-loading";
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { LabelErro } from '../../components/Layout/LabelErro'
import { LabelSucesso } from '../../components/Layout/LabelSucesso'
import api from '../../services/api';
import { URL } from '../../constants/index'

import "./styles.css";

const PrimeiroLogin = () => {
  const [email, setEmail] = useState("");
  const [erroLogin, setErroLogin] = useState("");
  const [sucessoLogin, setSucessoLogin] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleEmail = e => {
    setEmail(e.target.value)
  }

  const onRetornarLogin = e => {
    history.push(URL.URLLogin)
  }

  const loadingStatus = (status) => {
    setLoading(status);
  }

  const handlePrimeiroLogin = e => {
    e.preventDefault();

    if (!validarPrimeiroLogin()) {
      return false;
    }

    loadingStatus(true)

    api.post("/api/login/primeirologin", {
      email
    })
      .then(res => {
        tratarSucessoPrimeiroLogin()
        loadingStatus(false)
    })
    .catch(err => {
      loadingStatus(false)
        if (err.response && err.response.data) {
          tratarErroPrimeiroLogin(err.response.data.message)
        } else {
          tratarErroPrimeiroLogin("Erro ao se comunicar com API")
        }
    })
  }

  const tratarSucessoPrimeiroLogin = e => {
    setSucessoLogin("E-mail com instruções foi enviado com sucesso");
    setErroLogin("");
  }

  const validarPrimeiroLogin = e => {
    if (!email) {
      tratarErroPrimeiroLogin("Preencha e-mail para continuar");
      return false;
    }
    return true;
  }

  const tratarErroPrimeiroLogin = erro => {
    setErroLogin(erro);
    setSucessoLogin("");
  }

  return (
    <Container className="container">
      <Row className="justify-content-center">
        <Col
          xs = "6"
          className = "border col-padding"
        >
          <Form onSubmit={handlePrimeiroLogin}>
            <h3>Primeiro login</h3>
            <h6>Informe seu e-mail para receber o token de autenticação</h6>
            <hr/>
            {erroLogin ? <LabelErro caption={erroLogin}></LabelErro> : null}
            {sucessoLogin ? <LabelSucesso caption={sucessoLogin}></LabelSucesso> : null}
            <Form.Group controlId="email">
              <Form.Label>
                Email
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                onChange={handleEmail}
                value={email}
              />
            </Form.Group>

            {!loading ?
              <Button variant="dark" type="submit" block>Enviar</Button>
              :
              <Row className="justify-content-center">
                <ReactLoading
                  type="bubbles"
                  color="#343a40"
                  height={100}
                  width={100}
                />
              </Row>
            }
          </Form>
          <br />
          <Button
            variant="dark"
            onClick={onRetornarLogin}
            block
          >
            Retornar para login
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default PrimeiroLogin;
