import React, { Component, Fragment } from 'react';
import './App.css';
import Routes from './routes'

class App extends Component {
  render() {
    return <Fragment>
      <Routes />
    </Fragment>
  }
}

export default App;
