import React, { Component } from 'react'
import Dashboard from '../pages/Dashboard'
import { getUrlPadraoAtual, getUrlPadraoAtualMobile } from '../services/auth'

class DashboardAtual extends Component {
  render(){
    return(
        <Dashboard
          URLDashboard="dashboardatual"
          history={this.props.history}
          pageName={getUrlPadraoAtual()}
          pageNameMobile={getUrlPadraoAtualMobile()}
        />
    )
  }
}

export default DashboardAtual;
