import React, { useState } from 'react'
import qs from 'qs'
import { Button, Form } from 'react-bootstrap';
import { Container, Row, Col } from 'reactstrap';
import ReactLoading from "react-loading";
import MediaQuery from 'react-responsive';
import { useHistory } from 'react-router-dom';

import { login } from '../../services/auth'
import { LabelErro } from '../../components/Layout/LabelErro'
import api from '../../services/api'
import { URL } from '../../constants/index'

import "./styles.css";

const Login = () => {
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [erroLogin, setErroLogin] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const onPrimeiroLogin = async (e) => {
    history.push(URL.URLPrimeiroLogin)
  }

  const onRecuperarSenha = async (e) => {
    history.push(URL.URLRecuperarSenha)
  }

  const validarLogin = (e) => {
    if (!usuario || !senha) {
      tratarErroLogin("Preencha usuário e senha para continuar");
      return false;
    }
    return true;
  }

  const tratarErroLogin = (erroLogin) => {
    setErroLogin(erroLogin);
    setSenha("");
    setLoading(false);
  }

  const tratarSucessoLogin = (data) => {
    login(data);
    history.push(URL.URLDashboardAtual);
    setLoading(false);
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    setLoading(true);

    if (!validarLogin()) {
      return false;
    }

    api.post("/login/token",
      qs.stringify({
        grant_type: 'password',
        username: usuario,
        password: senha
      }), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => {
        tratarSucessoLogin(res.data);
      })
      .catch(err => {
        if (err.response && err.response.status === 400) {
          tratarErroLogin(err.response.data.error + " - " + err.response.data.error_description)
        } else {
          tratarErroLogin("Erro ao se comunicar com API de Login")
        }
    });
  }

  return(
    <Container className="container">
      <Row className="justify-content-center">
        <Col xs="6" className="border col-padding">
          <Form onSubmit={handleLogin}>
            <h3>Login</h3>
            <h6>Informe seu usuário e senha para ter acesso ao dashboard</h6>
            <hr />

            {erroLogin ?
              <LabelErro caption={erroLogin}></LabelErro>
              :
              null
            }

            <Form.Group controlId="usuario">
              <Form.Label>Usuário</Form.Label>
              <Form.Control
                type="text"
                placeholder="Usuario"
                onChange={(e) => setUsuario(e.target.value)}
                value={usuario}
              />
            </Form.Group>

            <Form.Group controlId="senha">
              <Form.Label>Senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="Senha"
                onChange={(e) => setSenha(e.target.value)}
                value={senha}
              />
            </Form.Group>

            {!loading ?

              <Button
                variant="dark"
                type="submit"
                block
              >
                Login
              </Button>
              :
              <Row className="justify-content-center">
                <ReactLoading
                  type="bubbles"
                  color="#343a40"
                  height={100}
                  width={100}
                />
              </Row>
            }
        </Form>
        <br />
        <MediaQuery minDeviceWidth={1224}>
          <Row className="justify-content-center">
            <Col>
              <Button
                variant="dark"
                type="submit"
                onClick={onPrimeiroLogin}
                block
              >
                Primeiro Login
              </Button>
            </Col>
            <Col>
              <Button
                variant="dark"
                type="submit"
                onClick={onRecuperarSenha}
                block
              >
                Recuperar senha
              </Button>
            </Col>
          </Row>
        </MediaQuery>

        <MediaQuery maxDeviceWidth={1224}>
            <Row className="justify-content-center">
              <Col>
                <Button
                  variant="dark"
                  type="submit"
                  onClick={onPrimeiroLogin}
                  block
                >
                  Primeiro Login
                </Button>
              </Col>
            </Row>
            <Row>&nbsp;</Row>
            <Row className="justify-content-center">
              <Col>
                <Button
                  variant="dark"
                  type="submit"
                  onClick={onRecuperarSenha}
                  block
                >
                  Recuperar senha
                </Button>
              </Col>
            </Row>

          </MediaQuery>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
