import React, { Component } from 'react'
import Dashboard from '../pages/Dashboard'
import { getUrlPadraoMensal, getUrlPadraoMensalMobile } from '../services/auth'

class DashboardAtual extends Component {
    render(){
        return(
            <Dashboard URLDashboard="dashboardmensal" history={this.props.history}  pageName={getUrlPadraoMensal()} pageNameMobile={getUrlPadraoMensalMobile()}/>
        )
    }
}

export default DashboardAtual;
