import React, { useState } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import ReactLoading from "react-loading";
import { useHistory } from 'react-router-dom';

import { LabelErro } from '../../components/Layout/LabelErro'
import { LabelSucesso } from '../../components/Layout/LabelSucesso'
import api from '../../services/api';
import { URL } from '../../constants/index'
import "./styles.css"

const RecuperarSenha = () => {
  const [email, setEmail] = useState("");
  const [erroSenha, setErroSenha] = useState("");
  const [sucessoSenha, setSucessoSenha] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const onRetornarLogin = e => {
    history.push(URL.URLLogin);
  }

  const loadingStatus = (status) => {
    setLoading(status);
  }
/*
  const startLoading = _ => {
    setLoading(true);
  }

  const stopLoading = _ => {
    setLoading(false);
  } */

  const handleRecuperarSenha = e => {
    e.preventDefault();

    if (!validarFormulario()) {
        return false;
    }

    loadingStatus(true)

    api.post("/api/login/recuperarsenha", { email }, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => {
      tratarSucessoRecuperarSenha()
      loadingStatus(false);
    })
    .catch(err => {
      loadingStatus(false);

      if (err.response && err.response.data) {
        tratarErroRecuperarSenha(err.response.data.message)
      } else {
        tratarErroRecuperarSenha("Erro ao se comunicar com API")
      }
    })
  }

  const tratarSucessoRecuperarSenha = e => {
    setSucessoSenha("E-mail com instruções foi enviado com sucesso");
    setErroSenha("");
  }

  const validarFormulario = e => {
    if (!email) {
      tratarErroRecuperarSenha("Preencha e-mail para continuar")
      return false;
    }
    return true;
  }

  const tratarErroRecuperarSenha = erro => {
    setErroSenha(erro);
    setSucessoSenha("");
  }

  return (
    <Container className="container">
      <Row className="justify-content-center">
        <Col
          xs="6"
          className="border col-padding"
        >
        <Form onSubmit={handleRecuperarSenha}>
          <h3>Recuperar senha</h3>
          <h6>Informe seu e-mail para receber o token de autenticação</h6>
          <hr/>
          {erroSenha ? <LabelErro caption={erroSenha}></LabelErro> : null}
          {sucessoSenha ? <LabelSucesso caption={sucessoSenha}></LabelSucesso> : null}
          <Form.Group controlId="email">
            <Form.Label>
              Email
            </Form.Label>
            <Form.Control
              type="email"
              placeHolder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Form.Group>

          {!loading ?
            <Button
              variant="dark"
              type="submit"
              block
            >
              Enviar
            </Button>
            :
            <Row className="justify-content-center">
              <ReactLoading
                type="bubbles"
                color="#343a40"
                height={100}
                width={100}
              />
            </Row>
          }
        </Form>
        <br />
        <Button
          variant="dark"
          onClick={onRetornarLogin}
          block
        >
          Retornar para login
        </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default RecuperarSenha;
