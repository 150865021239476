import React from "react";

export const LabelSucesso = props => (
  <p style={{ color: 'green' }}>
    <strong>
      {props.caption}
    </strong>
  </p>
)


