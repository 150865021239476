import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import ReactLoading from "react-loading";
import { useHistory, useLocation } from 'react-router-dom';

import { LabelErro } from '../../components/Layout/LabelErro'
import api from '../../services/api'
import { URL } from '../../constants/index'
import "./styles.css"

const CadastrarSenha = (props) => {
  const [token, setToken] = useState("");
  const [erroCadastrar, setErroCadastrar] = useState("");
  const [senha, setSenha] = useState("");
  const [repetirSenha, setRepetirSenha] = useState("");
  const [tokenInvalido, setTokenInvalido] = useState(true);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const definirToken = () => {
    //const currentPath = location.pathname;
    const param = new URLSearchParams(props.location.search);
    const token = param.get("token");
    setToken(token);

    return token;
  }

  const loadingStatus = (status) => {
    setLoading(status);
  }

  // useEffect executa apenas uma vez
  useEffect (() => {
    const token = definirToken();

    api.get("api/login/confirmartokensenha", {
      params: {
        token
      }
    })
    .then(res => {
      tratarTokenValido();
      loadingStatus(false);
    })
    .catch(err => {
      tratarTokenInvalido();
      loadingStatus(false);
    })
  },[]);

  const tratarTokenValido = e => {
    setTokenInvalido(false);
  }

  const tratarTokenInvalido = e => {
    setTokenInvalido(true);
  }

  const tratarErroCadastrarSenha = erro => {
    setErroCadastrar(erro);
  }

  const handleCadastrarSenha = e => {
    e.preventDefault()
    if (!validarFormulario()) {
      return false;
    }
    loadingStatus(true);

    api.post("/api/login/alterarsenha",
      {
        token,
        senha: btoa(senha)
      },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
    .then(res => {
      loadingStatus(false);
      onRetornarLogin();
    })
    .catch(err => {
      loadingStatus(false);
      if (err.response && err.response.data) {
        tratarErroCadastrarSenha(err.response.data.message);
      } else {
        tratarErroCadastrarSenha("Erro ao se comunicar com API");
      }
    });
  }

  const validarFormulario = () => {
    if (!senha || !repetirSenha) {
      tratarErroCadastrarSenha("Ambas as senhas precisam ser informadas")
      return false;
    }

    if (senha !== repetirSenha) {
      tratarErroCadastrarSenha("As senhas não coincidem")
      return false;
    }
    return true;
  }

  const onRetornarLogin = e => {
    history.push(URL.URLLogin);
  }

  if (loading) {
    return (
          <Container>
              <Row className="justify-content-center">
                  <ReactLoading
                    type="bubbles"
                    color="#343a40"
                    height={100}
                    width={100}
                  />
              </Row>
          </Container>
      )
  }

  if (tokenInvalido) {
      return (
          <Container classeName="container">
              <Row className="justify-content-center">
                  <Col xs="6" className="border col-padding" >
                      <Form>
                          <h3>Cadastrar senha</h3>
                          <hr />
                          <LabelErro caption="Token inválido"></LabelErro>
                      </Form>
                  </Col>
              </Row>
          </Container>
      )
  }

  return (
    <Container className="container">
      <Row className="justify-content-center">
        <Col xs="6" className="border col-padding" >
          <Form onSubmit={handleCadastrarSenha}>
            <h3>Cadastrar senha</h3>
            <hr />
            {erroCadastrar ? <LabelErro caption={erroCadastrar}></LabelErro> : null}
            <Form.Group controlId="senha">
              <Form.Label>
                Senha
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Senha"
                onChange={(e) => setSenha(e.target.value)}
                value={senha}
              />
            </Form.Group>

            <Form.Group controlId="repetir-senha">
              <Form.Label>
                Repetir senha
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Repetir senha"
                onChange={(e) => setRepetirSenha(e.target.value)}
                value={repetirSenha}
              />
            </Form.Group>

            {!loading ?
              <Button variant="dark" type="submit" block>Definir senha</Button>
              :
              <Row className="justify-content-center">
                <ReactLoading
                  type="bubbles"
                  color="#343a40"
                  height={100}
                  width={100}
                />
              </Row>
            }
          </Form>
          <br />
          <Button
            variant="dark"
            onClick={onRetornarLogin}
            block
          >
            Retornar para login
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default CadastrarSenha;
