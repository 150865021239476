import axios from "axios";
import { getToken } from "./auth";
import {PBIUrlAPI} from "../constants/index"


const api = axios.create({
  baseURL: PBIUrlAPI
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = "Bearer " + token
  }
  return config;
},error => {Promise.reject(error)});

export default api;