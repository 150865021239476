import Cookies from 'universal-cookie';
import api from '../services/api'
import qs from 'qs'

const cookies = new Cookies();

export const TOKEN_KEY = "@PBIVolpeLab-Token";
export const REFRESHTOKEN_KEY = "@PBIVolpeLab-RToken";
export const URLPADRAOATUAL_KEY = "@PBIVolpeLab-URLPadraoAtual";
export const URLPADRAOMENSAL_KEY = "@PBIVolpeLab-URLPadraoMensal";
export const URLPADRAOATUALMOBILE_KEY = "@PBIVolpeLab-URLPadraoAtualMobile";
export const URLPADRAOMENSALMOBILE_KEY = "@PBIVolpeLab-URLPadraoMensalMobile";


export const getToken = () => cookies.get(TOKEN_KEY);
export const getRefreshToken = () => cookies.get(REFRESHTOKEN_KEY);
export const getUrlPadraoAtual = () => cookies.get(URLPADRAOATUAL_KEY);
export const getUrlPadraoMensal = () => cookies.get(URLPADRAOMENSAL_KEY);
export const getUrlPadraoAtualMobile = () => cookies.get(URLPADRAOATUALMOBILE_KEY);
export const getUrlPadraoMensalMobile = () => cookies.get(URLPADRAOMENSALMOBILE_KEY);

export const login = (data) => {    
  cookies.set(TOKEN_KEY, data.access_token);
  cookies.set(REFRESHTOKEN_KEY, data.refresh_token);
  cookies.set(URLPADRAOATUAL_KEY, data.url_padrao_atual)
  cookies.set(URLPADRAOMENSAL_KEY, data.url_padrao_mensal)
  cookies.set(URLPADRAOATUALMOBILE_KEY, data.url_padrao_atual_mobile)
  cookies.set(URLPADRAOMENSALMOBILE_KEY, data.url_padrao_mensal_mobile)
};

export const logout = async () => {
  
  var token = getRefreshToken()
  const resp = api.post("/api/login/logout", {token: token})
  
  cookies.remove(TOKEN_KEY)
  cookies.remove(REFRESHTOKEN_KEY)
  cookies.remove(URLPADRAOATUAL_KEY)
  cookies.remove(URLPADRAOMENSAL_KEY)
  cookies.remove(URLPADRAOATUALMOBILE_KEY)
  cookies.remove(URLPADRAOMENSALMOBILE_KEY)
  return await resp;
  
};

export const confirmarAutenticacao = async () => {
  const resp = api.get("api/login/confirmarautenticacao")
  return await resp;
}

export const obterNovaAutenticacao = async () => {

  var refreshToken = getRefreshToken()

  const resp = api.post("/login/token",
    qs.stringify({
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  )

  return await resp;
}

