import React from "react";
import { HashRouter,BrowserRouter, Route, Switch } from "react-router-dom";

import Login from './pages/Login'
import DashboardAtual from "./components/DashboardAtual";
import DashboardMensal from "./components/DashboardMensal";
import withAuth from './components/withAuth';
import PrimeiroLogin from './pages/PrimeiroLogin'
import CadastrarSenha from './pages/CadastrarSenha'
import RecuperarSenha from './pages/RecuperarSenha'
import { URL } from './constants/index'

const Routes = () => (
  <HashRouter>
    <Switch>
      <Route exact path={URL.URLLogin} component={Login} />
      <Route exact path={URL.URLPrimeiroLogin} component={PrimeiroLogin} />
      <Route exact path={URL.URLCadastrarSenha} component={CadastrarSenha} />
      <Route exact path={URL.URLRecuperarSenha} component={RecuperarSenha} />

      <Route exact path={URL.URLDashboardAtual} component={withAuth(DashboardAtual)} />
      <Route exact path={URL.URLDashboardMensal} component={withAuth(DashboardMensal)} />
      <Route path="/" component={Login} />
    </Switch>
  </HashRouter>
);

export default Routes;
